
import React from "react"
import {graphql} from 'gatsby'
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Layout from '../../../layout'
import Seo from "../../../components/Seo"

const styles = {
  row: {
    '&>*': {
      paddingBottom: '0',
      borderBottom: 'unset',
    },
  },
  nowrap: {
    whiteSpace: 'nowrap'
  }
}

const Row = ({
  task
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow css={styles.row}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {task.title}
        </TableCell>
        <TableCell css={styles.nowrap} align="right">{task.due_date}</TableCell>
        <TableCell css={styles.nowrap} align="right">{task.assignee}</TableCell>
        <TableCell css={styles.nowrap} align="right">{task.status}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingTop: 0 }} colspan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {task.description}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default function Dashboard ({
  data,
  location,
}) {
  return (
    <Layout>
      <Seo
        lang={"en"}
        title="Tasks management"
        description={``}
        keywords={[].join(', ')}
        slug={location.pathname}
      />
      <Typography variant="h1" gutterBottom={true}>Tasks management</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{ width: '100%' }}>title</TableCell>
              <TableCell css={styles.nowrap} align="right">Due date</TableCell>
              <TableCell css={styles.nowrap} align="right">assignee</TableCell>
              <TableCell css={styles.nowrap} align="right">status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { data.tasks.nodes.map( (task) => (
              <Row key={task.due_date+task.title} task={task} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
    </Layout>
  )
}

export const query = graphql`
  query {
    tasks: allCloudTask(
      filter: { lang: { eq: "en" } }
      sort: { order: DESC, fields: [due_date] }
    ) {
      nodes {
        due_date(formatString: "ll", locale: "en")
        title
        description
        assignee
        status
      }
    }
  }
`
